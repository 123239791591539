.carouselItem {
  min-width: 105px;
  max-width: 105px;
  height: 150px;
  background: #ffffff;
  border-radius: 4px;
  margin: 0 4px;
  padding: 16px 4px;
}

@media (max-width: 768px) {
  .carouselItem {
    min-width: 80px;
    max-width: 80px;
    height: 112px;
  }

  .carouselItem:first-child {
    margin-left: 14px;
  }
  .carouselItem:nth-last-child(1) {
    margin-right: 14px;
  }
  .container {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .carouselItem:nth-last-child(1) {
    margin-right: 14px;
  }
}
