.carouselContainer {
  // overflow-y: hidden;
  position: relative;
  display: flex;
  width: calc(100%);
  // scrollbar-width: none;
  // -ms-overflow-style: none;
  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #181818;
  }
  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
  }
}

.carouselContainer::-webkit-scrollbar {
  // display: none;
}
.carouselItem {
  min-width: 105px;
  max-width: 105px;
  height: 150px;
  background: #ffffff;
  border-radius: 4px;
  margin: 0 4px;
  padding: 16px 4px;
}
.container {
  width: fit-content;
}
@media (max-width: 768px) {
  .carouselContainer {
    width: calc(100%);
  }
  .container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
