.buyersGuideContent {
  display: flex;
  flex-direction: column;

  a {
    color: #ffb00f;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 18px;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Rajdhani;
    font-weight: bold;
    padding-top: 15px;
    color: #939393;

    @media screen and (min-width: 991px) {
      color: #181818;
    }
  }

  h2 {
    font-size: 37px;
    line-height: 0.864em;
    padding-bottom: 15px;

    @media (max-width: 991px) {
      font-size: 31px;
      line-height: 1.03em;
    }
  }

  h3 {
    font-size: 31px;
    padding-bottom: 12px;

    @media (max-width: 991px) {
      font-size: 26px;
      line-height: 1.15em;
    }
  }

  h4 {
    font-size: 26px;
    padding-bottom: 12px;

    @media (max-width: 991px) {
      font-size: 21px;
      line-height: 1em;
    }
  }

  h5 {
    font-size: 21px;
    padding-bottom: 6px;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 1.16em;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    @media only screen and (min-width: 991px) {
      line-height: 1em;
    }
  }

  ul,
  ol {
    padding-left: 18px;
  }

  p,
  li {
    color: #181818;
    font-size: 16px;
    line-height: 1.75em;

    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  li {
    margin-left: 12px;
    list-style: disc;
  }

  p {
    padding-bottom: 10px;
  }
}
